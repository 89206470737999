import React from "react";
import { Helmet } from "react-helmet";

const Index = ({ ogTitle, title, description, image, url }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <title>{title}</title>
      <meta name="og:title" content={ogTitle} />
      <meta
        name="og:site_name"
        content='MASTERWiZR'
      />
      <meta name="og:description" content={description} />
      <meta
        name="og:type"
        content= "website"
      />
      <meta name="og:image" content={image} />
      <meta name="og:url" content={url} />
    </Helmet>
  );
};

export default Index;
